import React from 'react';

import { Heading, Container, Text, Link } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/layout/seo';
import { Site } from '../types';

const Impressum: React.FC<unknown> = () => {
  const { site }: Site = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            email
            phone
            fax
            address {
              street
              zip
              city
            }
          }
        }
      }
    `
  );

  return (
    <Layout
      useFixedHeader
      sx={{
        '.chakra-text': {
          mb: 5,
        },
        '.chakra-heading': {
          mb: 6,
        },
      }}
    >
      <Seo title="Impressum" description="Impressum und rechtliche Angaben" />
      <Container pt={10}>
        <Heading as="h1" size="xl">
          Impressum
        </Heading>
        <Heading as="h2" size="md">
          Angaben gemäß § 5 TMG
        </Heading>
        <Text mb={5} as="address" fontStyle="normal">
          {site.siteMetadata.title}
          <br />
          {site.siteMetadata.address.street}
          <br />
          {site.siteMetadata.address.zip} {site.siteMetadata.address.city}
        </Text>
        <Text>Vertreten durch: Martin Speiser und Stefan Schweizer</Text>
        <Heading as="h2" size="md">
          Kontakt
        </Heading>
        <Text>
          Telefon:{' '}
          <Link
            variant="unstyled"
            href={`tel:${site.siteMetadata.phone}`}
            title="S&S Zimmerei anrufen"
          >
            {site.siteMetadata.phone}
          </Link>
          <br />
          Telefax: {site.siteMetadata.fax}
          <br />
          E-Mail:{' '}
          <Link
            variant="unstyled"
            href={`mailto:${site.siteMetadata.email}`}
            title="Mail an S&S Zimmerei"
          >
            {site.siteMetadata.email}
          </Link>
        </Text>
        <Heading as="h2" size="md">
          Umsatzsteuer-ID
        </Heading>
        <Text>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
          <br />
          DE277396441
        </Text>
        <Heading as="h2" size="md">
          Berufsbezeichnung und berufsrechtliche Regelungen
        </Heading>{' '}
        <Text>
          Berufsbezeichnung: Zimmerer
          <br />
          Zuständige Kammer: Handwerskammer Reutlingen
          <br />
          Verliehen in: Deutschland
          <br />
          Regelungen einsehbar unter:
          https://www.hwk-reutlingen.de/top-menue/ueber-uns/rechtsgrundlagen.html
        </Text>
        <Heading as="h2" size="md">
          EU-Streitschlichtung
        </Heading>
        <Text>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{' '}
          <Link
            href="https://ec.europa.eu/consumers/odr/"
            title="Zur Online-Streitbeilegung der Europäischen Komission"
          >
            https://ec.europa.eu/consumers/odr/
          </Link>
          .
        </Text>
        <Heading as="h2" size="md">
          Verbraucherstreitbeilegung/Universalschlichtungsstelle
        </Heading>
        <Text>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </Text>
        <Heading as="h2" size="md">
          Haftung für Inhalte
        </Heading>
        <Text>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </Text>
        <Heading as="h2" size="md">
          Haftung für Links
        </Heading>
        <Text>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </Text>
        <Heading as="h2" size="md">
          Urheberrecht
        </Heading>
        <Text>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </Text>
      </Container>
    </Layout>
  );
};

export default Impressum;
